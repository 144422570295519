import React from "react";
import Navbar from "../components/Navbar";

const DashboardPage = () => {
    return(
        <React.Fragment>
            <Navbar />
        </React.Fragment>
    )
}

export default DashboardPage;